<template>
  <div>
    <!-- Filters -->

    <!-- <app-collapse hover>
      <app-collapse-item title="Filters">
        <EmployeeLeaveListFilters
          :status-filter.sync="statusFilter"
          :status-options="employeeLeaveStatusOption"
        />
      </app-collapse-item>
    </app-collapse> -->

    <b-card>
      <div class="w-100 text-right">
        <b-button
          v-if="$can('employee_leave_create')"
          variant="outline-primary"
          class="mr-1"
          :to="{ name: 'add-employee-leave' }"
        >
          <!-- @click="(value)=> postLeaveTransaction = !postLeaveTransaction" -->
          <!-- <feather-icon icon="" class="mr-50" /> -->
          <span class="align-middle">Post leave transaction</span>
        </b-button>
      </div>

      <div class="row">
        <div class="col-sm-6">
          Select Employee
          <v-select
            v-if="$can('Zircly Admin')"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="employeeFilter"
            :options="employeeListOptions"
            class="w-100"
            label="label"
            :reduce="(val) => val.code"
            placeholder="Select Employee.."
          />
          <v-select
            v-else
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="employeeFilter"
            :options="teamlistOptions"
            class="w-100"
            label="label"
            :reduce="(val) => val.code"
            placeholder="Select Employee.."
          />
        </div>

        <div class="col-sm-6">
          Select Year
          <b-form-spinbutton
            v-model="sessionFilter"
            min="2020"
            :max="new Date().getFullYear()+1"
          />
        </div>
      </div>
      <div class="w-100 table-responsive">
        <table class="w-100 mt-2 table" v-if="!postLeaveTransaction">
          <thead>
            <tr class="text-uppercase">
              <td>leave type</td>
              <td>granted</td>
              <td>consumed</td>
              <td>approval pending</td>
              <td>balance</td>
              <td>encashed count</td>
              <td v-if="$can('Zircly Admin') || $can('employee_leave_create')">Action</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in employeeLeavesList" v-bind:key="item.id">
              <td>
                {{ item.leave_type }}
              </td>
              <td>
                {{ item.granted }}
              </td>
              <td>
                {{ item.consumed }}
              </td>
              <td>
                {{ item.approval_pending }}
              </td>
              <td>
                {{ item.balance }}
              </td>
              <td>
                {{ item.encashed_count }}
              </td >
              <td  v-if="$can('Zircly Admin') || $can('employee_leave_create')" @click="editRecord(item)" ><svg data-v-f9caeeae="" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" title="Edit" class="mr-1 cursor-pointer feather feather-edit" v-if="$can('employee_leave_create')" ><path data-v-f9caeeae="" d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path data-v-f9caeeae="" d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="" v-if="postLeaveTransaction"></div>
    </b-card>

    <!-- Modal: import Record -->

    <b-modal
      id="modal-import-record"
      title="Import"
      centered
      :hide-footer="true"
    >
      <div
        v-if="$can('employee_create')"
        class="d-flex ml-2 align-items-center"
      >
        <b-form autocomplete="off" id="bulk-import" name="bulk-import" style="width: 100%">
          <file-pond
            ref="bulkdata"
            name="bulkdata"
            max-file-size="10MB"
            max-files="1"
            label-idle="Employee Leaves Import"
            allow-multiple="false"
            accepted-file-types="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
            file-validate-type-label-expected-types="Expects a Excel file"
            @addfile="fileSelected"
            @removefile="fileSelected"
          />
          <small class="text-warning flex items-center">
            <feather-icon icon="AlertCircleIcon" class="mr-25" />
            Allowed formats are
            <b>.xlsx, .csv</b>
          </small>
          <br />
          <div class="text-center w-100 mt-1">
            <button
              type="button"
              class="btn btn-success"
              @click="importBulkData"
              v-if="isFileSelected"
            >
              Import
            </button>
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal
  id="modal-select2"
  title="Employee Leave Balances"
  ok-title="update"
  cancel-variant="outline-secondary"
  @ok="updateLeaveBalances"
  @hide="resetValidation"
>
 <b-form>
        <b-form-group label="Leave Type" label-for="type">
          <b-form-input id="type" v-model="leaveForm.leave_type" :readonly="true" placeholder="" />
        </b-form-group>
        <b-form-group label="Granted" label-for="grant">
          <b-form-input id="grant" v-model="leaveForm.granted" :state="validation.granted" placeholder="" />
        </b-form-group>
        <b-form-group label="Consumed" label-for="consumed">
          <b-form-input id="consumed" v-model="leaveForm.consumed" :state="validation.consumed" placeholder="" />
        </b-form-group>
        <b-form-group label="Approval Pending" label-for="pending">
          <b-form-input id="pending" v-model="leaveForm.approval_pending" :state="validation.approval_pending" placeholder="" />
        </b-form-group>
        <b-form-group label="Balance" label-for="balance">
          <b-form-input id="balance" v-model="leaveForm.balance" :state="validation.balance" placeholder="" />
        </b-form-group>
        <b-form-group label="Encashed Count" label-for="encashed">
          <b-form-input id="encashed" v-model="leaveForm.encashed_count" :state="validation.encashed_count" placeholder="" />
        </b-form-group>
      </b-form>
    </b-modal>


  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import employeeLeaveStoreModule from "../employeeLeaveStoreModule";
import useEmployeeLeaveList from "./useEmployeeLeaveList";
import EmployeeLeaveListFilters from "./EmployeeLeaveListFilters.vue";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    EmployeeLeaveListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
    FilePond,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-employee-leaves";

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        employeeLeaveStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    return {};
  },
  data() {
    return {
      leaveForm: {
      leave_type: '',
      granted: '',
      consumed: '',
      approval_pending: '',
      balance: '',
      encashed_count: ''
    },
     validation: {
        // leave_type: null,
        granted: null,
        consumed: null,
        approval_pending: null,
        balance: null,
        encashed_count: null
      },
      isFileSelected: false,
      employeeFilter: this.$cookies.get("userData")["hashid"],
      onlyCurrectEmployee: [
        {
          label:
            this.$cookies.get("userData")["first_name"] +
            "(" +
            this.$cookies.get("userData")["email"] +
            ")",
          code: this.$cookies.get("userData")["hashid"],
        },
      ],
      teamlistOptions: [],
      employeeListOptions: [],
      employeeLeavesList: [],
      sessionFilter: new Date().getFullYear(),
      postLeaveTransaction: false,
    };
  },
  watch: {
    employeeFilter(val) {
      if (val) {
        this.fetchEmployeeLeaves();
      }
    },
    sessionFilter(val) {
      if (val) {
        this.fetchEmployeeLeaves();
      }
    },
  },
  created() {
    this.fetchEmployeeList();
    this.fetchEmployeeLeaves();
    this.fetchTeamList();
  },
  methods: {
    fetchEmployeeLeaves() {
      if (this.employeeFilter) {
        this.$store
          .dispatch("app-employee-leaves/fetchEmployeeLeaves", {
            hashid: this.employeeFilter,
            year: this.sessionFilter,
          })
          .then((res) => {
            this.employeeLeavesList = res.data.data;
          })
          .catch((err) => {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "fetch Employee List failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: err.toString(),
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });
      }
    },
    fetchEmployeeList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    fetchTeamList() {
      this.$store
        .dispatch("app/fetchTeamList")
        .then((res) => {
          this.teamlistOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    editRecord(data) {
      console.log(data)
      // this.$router
      //   .push(`/edit/employee_leave/${data.user.hashid}`)
      //   .catch(() => {});

    this.leaveForm = { ...data };
    this.$bvModal.show('modal-select2');
    },

    updateLeaveBalances(bvModalEvt) {
    bvModalEvt.preventDefault();
    let isValid = true;

    const showToast = (message) => {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'calculation error',
      //     icon: "BellIcon",
      //     variant: "danger",
      //     text: message,
      //   },
      // });
      this.$toast({
              component: ToastificationContent,
              props: {
                title: 'calculation error',
                icon: 'BellIcon',
                variant: 'danger',
                text: message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
    };
    Object.keys(this.leaveForm).forEach(key => {

      if (key !== 'leave_type' && key !== 'user'  ) {
        if (this.leaveForm[key] === '') {
          this.validation[key] = false;
          isValid = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `${this.capitalizeFirstLetter(key)} cannot be empty.`,
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: `${this.capitalizeFirstLetter(key)} cannot be empty.`,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: `${this.capitalizeFirstLetter(key)} cannot be empty.`,
                icon: 'BellIcon',
                variant: 'danger',
                text: `${this.capitalizeFirstLetter(key)} cannot be empty.`,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        } else if (isNaN(this.leaveForm[key])) {
          this.validation[key] = false; // Add this line to mark the field as invalid
          isValid = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `${this.capitalizeFirstLetter(key)} must be a numeric value.`,
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: `${this.capitalizeFirstLetter(key)} must be a numeric value.`,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title:  `${this.capitalizeFirstLetter(key)} must be a numeric value.`,
                icon: 'BellIcon',
                variant: 'danger',
                text:`${this.capitalizeFirstLetter(key)} must be a numeric value.`,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        } else {
          this.validation[key] = true;
        }
      }
    });
    const { granted, balance, consumed, approval_pending, encashed_count } = this.leaveForm;
    const totalUsed = parseFloat(consumed) + parseFloat(approval_pending) + parseFloat(encashed_count);
    const calculatedBalance = parseFloat(granted) - totalUsed;

    if (parseFloat(granted) < totalUsed) {
      isValid = false;
      showToast(`The total of consumed, approval pending, and encashed should not exceed granted`);
    }

    if (parseFloat(balance) !== calculatedBalance) {
      isValid = false;
      showToast(`Granted must be equal to the sum of balance, approval pending, consumed, and encashed.`);
    }
    if (isValid) {
      this.$store.dispatch('app-employee-leaves/updateEmployeeLeaveBalance', this.leaveForm)
        .then(() => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Success',
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: 'Leave balances updated successfully!',
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Leave balances updated successfully!',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.resetValidation();
          this.fetchEmployeeLeaves();
          this.$bvModal.hide('modal-select2');
        })
        .catch(error => {
          console.error('Error updating leave balances:', error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Error',
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: 'Failed to update leave balances. Please try again.',
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Failed to update leave balances. Please try again.',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    }
  },
  resetValidation() {
    Object.keys(this.validation).forEach(key => {
      this.validation[key] = null;
    });
  },
  capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    viewRecord(data) {
      this.$router
        .push(`/view/employee_leave/${data.item.hashid}`)
        .catch(() => {});
    },
    fileSelected(e) {
      this.isFileSelected = !this.isFileSelected;
    },
    importBulkData() {
      const self = this;
      const formData = new FormData(document.getElementById("bulk-import"));
      formData.delete("bulkdata");

      var bulkdata = [];
      if (self.$refs.bulkdata.getFiles().length) {
        if (
          self.$refs.bulkdata.getFiles()[0].fileType !=
            "application/vnd.ms-excel" &&
          self.$refs.bulkdata.getFiles()[0].fileType !=
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          self.$refs.bulkdata.getFiles()[0].fileType != ".xlsx"
        ) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Only Excel file are allowed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "Only Excel file are allowed",
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          self.is_loading = false;
          return;
        }
      }

      if (this.$refs.bulkdata) {
        bulkdata = this.$refs.bulkdata.getFiles();
        if (bulkdata[0]) {
          formData.append("bulkdata", bulkdata[0].file);
        }
        this.$store
          .dispatch("app-employee-leaves/import", {
            data: formData,
            url: "employee-leaves/process-import",
          })
          .then((res) => {
            this.$bvModal.hide("modal-import-record");
            this.isFileSelected = false;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Record imported with success.",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Record imported with success.',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          })
          .catch((err) => {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Record imported with error.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: err.toString(),
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Record imported with error.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });
      }
    },

    refetchEmployeeLeavesData() {
      console.log("Added Employee_leave");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-employee-leaves/removeEmployeeLeave", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Employee_leave Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Employee_leave Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Employee_leave Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Employee_leave Deleted Successfully.',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Employee_leave Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Employee_leave Deletion failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Employee_leave Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Employee_leave Deletion failed.`,
              icon:'BellIcon',
              variant : 'danger',
              hideClose: true,
              
            },
                },
                {
            timeout : 3000,
            position : 'bottom-center',
            toastClassName:"error-info",
            hideProgressBar : true,
	      })
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.filepond--credits {
  display: none;
}

.filepond--panel-center .filepond--panel-root {
  height: 40px !important;
}
</style>
